import './NewsDetail.scss'

import Layout from 'EasyProjectGatsby/components/core/Layout'
import { graphql, PageProps } from 'gatsby'
import React, { FunctionComponent } from 'react'
import PuxVideo, {
  PuxVideoType,
} from 'Root/Shared/components/PuxVideo/PuxVideo'
import WidgetBuilder from 'Shared/components/builders/WidgetBuilder'
import PuxMediaSelector, {
  PuxMediaSelectorType,
} from 'Shared/components/content/mediaImage/PuxMediaSelector'
import EasySoftwareBreadcrumbs, {
  BreadcrumbItem,
} from 'Shared/components/EasySoftwareBreadcrumbs/EasySoftwareBreadcrumbs'
import PuxColumns, {
  PuxMainColumn,
  PuxSideColumn,
} from 'Shared/components/PuxColumns/PuxColumns'
import { localizedPathType } from 'Shared/components/PuxLanguageSelector/PuxLanguageSelector'
import PuxShare from 'Shared/components/PuxShare/PuxShare'
import PuxStructuredData from 'Shared/components/PuxStructuredData/PuxStructuredData'
import PuxWysiwyg, {
  PuxWysiwygHtmlBodyType,
} from 'Shared/components/PuxWysiwyg/PuxWysiwyg'
import { WidgetPuxSectionType } from 'Shared/components/widgets/WidgetPuxSection'
import getReadingDuration from 'Shared/utils/getReadingDuration'
import PuxMetaTags, { PuxMetaTagsType } from 'Shared/utils/PuxMetaTags'
import { msgid, ngettext, t } from 'ttag'

export interface NewsType {
  contentType: string
  contentItemId: string
  contentItemVersionId: string
  localization: {
    localizations: localizedPathType[]
  }
  path: string
  displayText: string
  createdUtc: string
  modifiedUtc: string
  newsAuthor: string
  newsPerex: PuxWysiwygHtmlBodyType
  newsContent: PuxWysiwygHtmlBodyType
  newsImage: PuxMediaSelectorType
  puxVideo: PuxVideoType
}

export interface NewsDetailType extends PageProps {
  data: {
    orchard: {
      easySoftwareNews: [NewsType]
    }
  }
  pageContext: {
    pagePath: string
    sideCardWidgets: any
    widgets: {
      widgetZone: [WidgetPuxSectionType]
    }
    metaTags: PuxMetaTagsType
    breadcrumbs: [BreadcrumbItem]
  }
}

export const query = graphql`
  query($pagePath: String = "") {
    orchard {
      easySoftwareNews(first: 1, where: { path: $pagePath }) {
        contentType
        contentItemId
        path
        localization {
          localizations {
            ... on Orchard_EasySoftwareNews {
              path
              published
              localization {
                culture
              }
            }
          }
        }
        displayText
        createdUtc
        modifiedUtc
        newsAuthor
        newsPerex {
          html
        }
        newsContent {
          html
        }
        newsImage {
          mediaSelectorImage {
            resizePaths(width: 1920, mode: "Min", quality: 75)
            resizePathsMobile(width: 768, mode: "Min", quality: 75)
            resizePathsTablet(width: 1200, mode: "Min", quality: 75)
          }
        }
        puxVideo {
          videoStructuredDataContentUrl
          videoStructuredDataDescription
          videoStructuredDataName
          videoStructuredDataUploadDate
          videoThumbnail {
            resizePaths(width: 1920, mode: "Min", quality: 75)
            resizePathsMobile(width: 768, mode: "Min", quality: 75)
            resizePathsTablet(width: 1200, mode: "Min", quality: 75)
            texts
          }
          videoYTID {
            html
          }
        }
      }
    }
  }
`

const rootClass = `news-detail`

function getNewsJson(item: NewsType) {
  const json = {
    '@context': `https://schema.org`,
    '@type': `NewsArticle`,
    headline: item.displayText,
    image: [
      item.newsImage?.mediaSelectorImage.resizePaths[0] ?? ``,
      item.newsImage?.mediaSelectorImage.resizePathsTablet[0] ?? ``,
      item.newsImage?.mediaSelectorImage.resizePathsMobile[0] ?? ``,
    ],
    datePublished: item.createdUtc,
    dateModified: item.modifiedUtc ?? ``,
  }

  return json
}

const NewsDetail: FunctionComponent<NewsDetailType> = (props) => {
  const pageData =
    props.data.orchard.easySoftwareNews &&
    props.data.orchard.easySoftwareNews[0]
  const path = props.pageContext.pagePath
  const date = new Date(pageData.createdUtc)
  const minutes = getReadingDuration([
    pageData.newsPerex.html,
    pageData.newsContent.html,
  ])

  const widgetData = props.pageContext.widgets.widgetZone
  const breadCrumbsData = props.pageContext.breadcrumbs
  const metaTags = props.pageContext.metaTags ?? {}

  if (!metaTags.metaTagsOGImage?.resizePaths?.length) {
    metaTags.metaTagsOGImage = pageData.newsImage?.mediaSelectorImage
  }

  return (
    <Layout localizedPath={pageData.localization?.localizations}>
      <EasySoftwareBreadcrumbs items={breadCrumbsData} />
      <PuxMetaTags {...metaTags} />
      <PuxStructuredData structuredData={getNewsJson(pageData)} />
      <div className={`${rootClass} pux-container`}>
        <PuxColumns>
          <PuxMainColumn>
            <h1 className={`${rootClass}-title`}>{pageData.displayText}</h1>

            <div className={`${rootClass}-meta`}>
              <div className={`${rootClass}-parameter`}>
                <strong className={`${rootClass}-parameter-label`}>
                  {t`EasySoftware.news.dateLabel`}:
                </strong>
                {date.toLocaleDateString()}
              </div>
              <div className={`${rootClass}-parameter`}>
                <strong className={`${rootClass}-parameter-label`}>
                  {t`EasySoftware.news.lengthLabel`}:
                </strong>
                {ngettext(
                  msgid`${minutes} minute`,
                  `${minutes} minutes`,
                  minutes
                )}
              </div>
              {pageData.newsAuthor && (
                <div className={`${rootClass}-parameter`}>
                  <strong className={`${rootClass}-parameter-label`}>
                    {t`EasySoftware.news.authorLabel`}:
                  </strong>
                  {pageData.newsAuthor}
                </div>
              )}
              <div className={`${rootClass}-parameter`}>
                <strong className={`${rootClass}-parameter-label`}>
                  {t`EasySoftware.news.shareLabel`}:
                </strong>
                <PuxShare
                  sites={[`Facebook`, `Twitter`, `Linkedin`, `Pinterest`]}
                  path={path}
                  showTitle={false}
                />
              </div>
            </div>

            <div className={`${rootClass}-perex perex`}>
              <PuxWysiwyg content={pageData.newsPerex} />
            </div>

            <div className={`${rootClass}-image`}>
              {pageData.puxVideo?.videoYTID.html && (
                <PuxVideo {...pageData.puxVideo} />
              )}
              {!pageData.puxVideo?.videoYTID.html && (
                <PuxMediaSelector {...pageData.newsImage} disableLazyload />
              )}
            </div>

            <div className={`${rootClass}-content`}>
              <PuxWysiwyg content={pageData.newsContent} />
            </div>

            <WidgetBuilder widgetBuilderData={widgetData} />
          </PuxMainColumn>
          <PuxSideColumn>
            <div className='sidebar-sticky'>
              <WidgetBuilder
                widgetBuilderData={props.pageContext.sideCardWidgets}
              />
            </div>
          </PuxSideColumn>
        </PuxColumns>
      </div>
    </Layout>
  )
}

export default NewsDetail
